<template>
  <div class="pledgedetail-style">
    <div class="bigbox">
      <div class="box1" v-if="device == 'h5'">Ture NFT#22221</div>
      <div :class="device == 'pc'?'box2-fa-fa':''">
        <div class="box2-fa center-center">
          <img src="@/assets/img/img64.png" alt="" class="box2" />
        </div>
        <div class="box4-fa">
          <template v-if="device == 'pc'">
            <div class="box1-1">{{$t('text.t89')}}</div>
            <div class="box1">Ture NFT#22221</div>
          </template>
          <div class="box4" v-if="$route.params.type == 'r'">
            <div class="b4-item">已质押时间：5天</div>
            <div class="b4-item">质押日期：2022/7/25/21:30</div>
            <div class="b4-item">剩余锁定时间：10天</div>
          </div>
          <div class="box4" v-if="$route.params.type == 'p' && device == 'pc'">
            <div class="b4-item">已质押时间：--</div>
            <div class="b4-item">质押日期：--</div>
            <div class="b4-item">剩余锁定时间：--</div>
          </div>
          <div class="box3 center-center" v-if="$route.params.type == 'p'">
            质押
          </div>
          <!-- <div class="box3 center-center" v-else-if="$route.params.type == 'r'">
            赎回
          </div> -->
          <div class="box5 center-center" v-else-if="$route.params.type == 'r'">
            赎回
          </div>
        </div>
      </div>
      <div :class="device == 'pc'?'box8-fa':''">
        <div class="box8">
          <div class="b8-t1">信息</div>
          <div class="b8-t2 between-center">
            <div>合约地址</div>
            <div>0×asdabkcj221asdcased</div>
          </div>
          <div class="b8-t2 between-center">
            <div>令牌ID</div>
            <div>1258</div>
          </div>
          <div class="b8-t2 between-center">
            <div>代币标准</div>
            <div>ERC-721</div>
          </div>
          <div class="b8-t2 between-center">
            <div>区块链</div>
            <div>以太坊</div>
          </div>
        </div>
        <div class="box8">
          <div class="b8-t1">质押说明：</div>
          <div class="b8-t2 b8-t3">
            质押说明，质押说明质押说明。质押说明质押说明质押说明质押说明，质押说，明质押说明。质押说明质，押说明质押说明。质押说明质押，说明质押说明质押说。明质押说明质押说，明质押说明质押说明质押说明质押，说明质押。说明质押，说明质押说，押说明质押说，明质。押说明质押说。明质押说明质押说明。质押说明质押说明质押，说明质押说。明质押说明质押说明质，押说明质，押说明质押说明质。押说明质押。质押说明。质押说明质押说明。说明质押说明质押说质押说明。质押说明，质押说明质押说明。质押说明质押说明质押说明质押说明，质押说，明质押说明。质押说明质，押说明质押说明。质押说明质押，说明质押说明质押说。质押说明。质押说明质押说明。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["device"]),
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less">
.pledgedetail-style {
  .bigbox {
    padding: 25px 0 50px;
    width: 300px;
    margin: auto;
    .box1 {
      font-size: 18px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 15px;
    }
    .box2 {
      width: 100%;
      height: auto;
      margin-bottom: 25px;
    }
    .box3 {
      height: 40px;
      background: #323232;
      border-radius: 10px;
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 25px;
    }
    .box5 {
      height: 40px;
      background: #e1e1e1;
      border-radius: 10px;
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 25px;
    }
    .box8 {
      background: #f4f4f4;
      border-radius: 10px;
      padding: 25px 22px;
      margin-bottom: 20px;
      .b8-t1 {
        text-align: center;
        margin-bottom: 24px;
        font-size: 15px;
        font-weight: bold;
        color: #323232;
      }
      .b8-t2 {
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        margin-bottom: 25px;
      }
      .b8-t2:last-of-type {
        margin-bottom: 0;
      }
      .b8-t3 {
        line-height: 1.5;
        font-weight: 500;
      }
    }
    .box4 {
      background: #f4f4f4;
      border-radius: 10px;
      margin-bottom: 25px;
      font-size: 12px;
      font-weight: bold;
      color: #323232;
      .b4-item {
        padding: 15px;
        border-bottom: 1px solid #e1e1e1;
      }
      .b4-item:last-of-type {
        border: none;
      }
    }
  }
  @media all and (min-width: 1080px) {
    .bigbox {
      padding: 60Px 0 98Px;
      width: 1058Px;
      margin: auto;
      .box1-1{
        font-size: 24Px;
        font-weight: bold;
        color: #284DA1;
        margin-bottom: 31Px;
      }
      .box1 {
        font-size: 36Px;
        font-weight: bold;
        color: #323232;
        margin-bottom: 77Px;
      }
      .box2-fa-fa{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 60Px;
      }
      .box2-fa{
        width: 500Px;
        height: 500Px;
        background-color: #F4F4F4;
        border-radius: 10Px;
      }
      .box2 {
        width: auto;
        max-width: 462Px;
        height: 462Px;
        margin-bottom: 0;
      }
      .box3 {
        height: 40px;
        background: #323232;
        border-radius: 10px;
        font-size: 15px;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 0;
      }
      .box5 {
        height: 40px;
        background: #e1e1e1;
        border-radius: 10px;
        font-size: 15px;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 0;
      }
      .box8-fa{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
      .box8 {
        width: 500Px;
        box-sizing: border-box;
        background: #f4f4f4;
        border-radius: 10px;
        padding: 25px 22px;
        margin-bottom: 0;
        .b8-t1 {
          text-align: center;
          margin-bottom: 24px;
          font-size: 15px;
          font-weight: bold;
          color: #323232;
        }
        .b8-t2 {
          font-size: 12px;
          font-weight: bold;
          color: #323232;
          margin-bottom: 25px;
        }
        .b8-t2:last-of-type {
          margin-bottom: 0;
        }
        .b8-t3 {
          line-height: 1.5;
          font-weight: 500;
        }
      }
      .box4-fa{
        width: 500Px;
      }
      .box4 {
        background: #fff;
        border-radius: 0;
        margin-bottom: 0;
        font-size: 24Px;
        font-weight: bold;
        color: #323232;
        .b4-item {
          padding: 0;
          border-bottom: none;
          margin-bottom: 60Px;
        }
        .b4-item:last-of-type {
          border: none;
        }
      }
    }
  }
}
</style>